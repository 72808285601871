.transition {
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.text-main {
  --tw-text-opacity: 1;
  color: rgb(0 145 218 / var(--tw-text-opacity));
}
.font-bold {
  font-weight: 700;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.border-main {
  --tw-border-opacity: 1;
  border-color: rgb(0 145 218 / var(--tw-border-opacity));
}
.border-b-2 {
  border-bottom-width: 2px;
}
.border-gray-400 {
  border-bottom: 1px solid #42526e;
}
.border-b {
  border-bottom-width: 1px;
}
.justify-between {
  justify-content: space-between;
}
.cursor-default {
  cursor: default;
}
.flex {
  display: flex;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.min-h-70 {
  min-height: 70vh;
}

.p-1 {
  padding: 0.25rem;
}
.mt-16 {
  margin-top: 4rem;
}
@media (min-width: 1536px) .container {
  max-width: 1536px;
}
@media (min-width: 1280px) .container {
  max-width: 1280px;
}
@media (min-width: 1024px) .container {
  max-width: 1024px;
}
@media (min-width: 768px) .container {
  max-width: 768px;
}
@media (min-width: 640px) .container {
  max-width: 640px;
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
