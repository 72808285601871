.label_title {
  color: rgb(122, 134, 154);
  font-size: 14px;
}

.login_container {
  width: 100%;
  overflow: hidden;
  background: black;
  background-image: url(../../assets/img/blue-background.png);
  height: 100%;
}
.login_input {
  border: 2.5px solid white;
  background-color: transparent !important;
  color: white;
  border-radius: 12px;
}

.login_input::placeholder {
  color: white;
}

.label_title2 {
  color: black;
  font-size: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .input_field {
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .input_field {
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .input_field {
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .input_field {
    padding-top: 2%;
    padding-bottom: 2%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .login_col {
    margin-right: 25%;
    margin-left: 25%;
  }
  .input_field {
    padding-top: 3%;
    padding-bottom: 3%;
  }
}

.input_field {
  height: 50px;
  border-radius: 10px;
  border: 1px solid #0091da;
}

.non_style_input {
  border: none;
  height: 50px;
  border-radius: 10px;
}

.input_field::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(206, 206, 206, 0.8);
  opacity: 1;
  font-size: 10px;
}

.input_field:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(206, 206, 206, 0.8);
  opacity: 1;
  font-size: 10px;
}

.input_field::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(206, 206, 206, 0.8);
  opacity: 1;
  font-size: 10px;
}

.btn_login {
  background-color: #003595 !important;
  color: white;
  padding: .8rem 5rem;
  background: #003595;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

/* .btn_login:hover {
  background-color: white;
  color: #00beac;
  border: none;
  height: 45px;
  width: 20%;
  box-shadow: 1px 1px 15px #00beac;
  transition: box-shadow 0.5s ease-in-out;
  border-radius: 10px;
  padding-inline: 5%;
  border-radius: 10px;
} */

.img_bottom {
  width: 400px;
  height: 400px;
  position: relative;
  bottom: 0;
  margin-top: -9%;
}

.pass-link {
  cursor: pointer;
  position: absolute;
  height: 130%;
  z-index: 1000;
  top: 0%;
  display: flex;
  align-items: center;
  float: left;
  direction: rtl;
}
.pass-link img {
  width: 25px;
  height: 25px;
}

.input_field:hover {
  box-shadow: none;
  border: 1px solid #0091da;
}
.input_field:active {
  box-shadow: none;
  border: 1px solid #0091da;
}
.input_field:focus {
  box-shadow: none;
  border: 1px solid #0091da;
}

.fullwidth {
  width: 100%;
}

.login_parent {
  height: 90vh;
  display: grid;
  place-items: center;
  width: 100%;
  /* position: absolute; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
