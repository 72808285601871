.home_card {
  background: white;
  /* border: 3px solid #00beac; */
  border: solid 3px #0edbc2;
  border-radius: 10px;
  height: 200px;
  display: flex;
  align-items: center;
  perspective: 1000px;
  box-shadow: 1px 1px 10px white;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.home_card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* border: 3px solid #00beac; */
  border-radius: 10px;
  box-shadow: 2px 2px 10px gray;
}

.flip-card-front:hover,
.flip-card-back:hover {
  box-shadow: 5px 5px 20px gray;
}

.flip-card-front {
  /* background-color: #bbb; */
  color: black;
}

.flip-card-back {
  background-color: #00beac;
  color: white;
  transform: rotateY(180deg);
}

.btn_go {
  background: white;
  border: 1px solid #00beac;
  color: #00beac;
  border-radius: 10px;
}

.btn_go:hover {
  background-color: #00beac;
  color: white;
  border: 1px solid white;
  box-shadow: 1px 1px 15px white;
  transition: box-shadow 0.5s ease-in-out;
  border-radius: 10px;
}

.title_div {
  background: #00beac;
  border: 5px solid white;
  color: white;
  position: absolute;
  z-index: 1000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 50%;
  margin-left: 50%;
  transform: translateX(-50%);

  /* box-shadow: 1px 1px 3px #eee; */
}

@media screen and (max-width: 700px) {
  .title_div {
    width: 100%;
    margin-left: auto;
    transform: translateX(-20px);
  }
}
