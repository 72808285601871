.card-wrapper {
  border: none;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px 30px;
}

.card-wrapper .card-container {
  width: 100%;
  font-size: 17px;
}

.card-container .count-number {
  color: #00beac;
}
.card-container .title {
  color: #000;
}
