/* html,
body {
  margin: 0px !important;
  padding: 0px !important;
  width: 100%;
  height: 100%;
} */

/* #root {
  width: 100%;
  height: 100%;
} */

/* .row {
  margin-right:auto !important;
  margin-left:auto !important
}
.card{
  display:block ;
} */

body{
  background: #F3F3F3 !important
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .form-gap {
    @apply gap-y-4 gap-x-10;
  }

  .section {
    @apply p-1 md:py-10 md:px-4 lg:px-16;
  }
}

@layer utilities {
  .section__header {
    color: #6b778c;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 2rem;
  }

  .min-h-70 {
    min-height: 70vh;
  }
}
