.home_card2Container {
  // width: 220px;
  // height: 220px;
  width: calc(220px * 2 / 3);
  height: calc(220px * 2 / 3);
  position: relative;
  background: linear-gradient(135deg, #a1ff8b 0%, #3f93ff 96.83%);
  border-radius: 40px;
  padding: 5px;
  box-shadow: -1.66667px -1.66667px 3.33333px #fafbff,
    1.66667px 1.66667px 3.33333px #a6abbd;

  .cardIcon {
    width: 44px;
    transition: width 400ms;
  }

  &:hover {
    box-shadow: -3.66667px -3.66667px 5.33333px #fafbff,
      3.66667px 3.66667px 5.33333px #a6abbd;
    cursor: pointer;

    .cardIcon {
      width: 66px;
    }
  }

  .home_card2 {
    background: white;
    border-radius: 40px;
    padding: 15px;
    width: 100%;
    height: 100%;
    position: relative;

    .counter_circle {
      position: relative;
      // width: 46px;
      // height: 46px;
      width: calc(46px * 2 / 3);
      height: calc(46px * 2 / 3);
      border-radius: 50%;
      background-color: #f2f2f2;
      box-shadow: -2.5px -2.5px 5px #fafbff, 2.5px 2.5px 5px #a6abbd;
    }
  }
}

.menuBtnDiv {
  text-align: start;
  display: none;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  width: fit-content;

  @media only screen and (max-width: 600px) {
    display: flex;
  }
}
