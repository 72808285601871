.logo-card {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.logo-card .main-logo {
  /* max-width: 500px; */
  width: 100%;
  max-height: 100%;
  border-radius: 10px;
}
.logo-card .main-logo img {
  border-radius: inherit;
}

.prograss-bar-container {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  /* margin-top: 20px; */
}
.prograss-card {
  /* width: 260px; */
  width: 100%;
  height: 110px;
  background-color: #fbfbfb;
}
.prograss-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  /* margin-inline: 30px; */
}
.prograss-card-container .item {
  margin-inline: 30px;
  margin-top: 5px;
  /* background-color: red; */
}
.prograss-card-container .item-text {
  width: 35%;
}
.progress-list {
  list-style: none;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.progress-list .progress-list-item {
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.progress-list .progress-list-item p {
  margin: 0px !important;
}
.prograss-card .prograss-card-container .item .prograss-img-container {
  width: 60px;
  height: 60px;
}

.prograss-card-container .item .label {
  color: #a3a8b8;
}

.revenue-card {
  /* padding: 10px 20px; */
  min-height: 340px;
  height: 340px;
  width: 100%;
}

.revenue-card .list-item {
  display: initial !important;
}

.inner-container {
  width: 100%;
}

.inner-container .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: transparent;
}

.revenue-card .inner-container .header .MuiTab-textColorPrimary.Mui-selected {
  background-color: transparent !important;
  color: gray !important;
}

.revenue-card .inner-container .header .css-1aquho2-MuiTabs-indicator {
  background-color: #6c5dd4 !important;
}

.revenue-card .desc {
  margin-inline-start: 30px;
}

.revenue-card .list-element {
  margin-inline: 10px;
  display: flex;
}

.revenue-card .list-element .circle {
  width: 12px;
  height: 12px;
  border-radius: 100%;
}
.revenue-card .list-element span {
  font-weight: normal;
  font-size: 0.9rem;
}
.revenue-card .list-element .value {
  margin-inline-start: 10px;
}
.revenue-card .list-element .title {
  margin-inline-start: 7px;
}

.revenue-card .desc .icon-container {
  border-radius: 100%;
  width: 16px;
  height: 16px;
  background-color: #6c5dd4;
  color: #fff;
}

.revenue-card .desc .title {
  margin-inline-start: 10px;
  color: #cbcbcb;
  font-weight: normal;
  font-size: 12px;
}

.inner-container .header {
  background-color: transparent;
  border: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inner-container .body {
  background-color: transparent;
  border: transparent;
}

.referrals-card {
  /* height: 300px; */
  height: 100%;
  width: 100%;
}
.referrals-card .body {
  background-color: transparent;
  border: transparent;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin-inline: 15px;
}

.referrals-card .body .text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  width: 150px;
}
.referrals-card .body .text-container span {
  font-size: 0.8rem !important;
}
.referrals-card .body .text-container .count {
  color: gray;
}

.referrals-card .body .list-item {
  border: 1px solid transparent;
}

.referrals-item-container {
  cursor: pointer;
  border: 1px solid transparent;
}

.referrals-item-container:hover {
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
}

.referrals-item-circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: none;
  margin-inline-end: 10px;
}

.referrals-card .body .referrals-item-container-text {
  margin: 0px;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}
.referrals-card .body .referrals-item-container-text .title {
  width: 170px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.referrals-card .header .reset {
  color: rgb(1, 128, 250);
  cursor: pointer;
}
.referrals-card .header .reset:hover {
  text-decoration: underline;
}

.referrals-card .body .statistics_item {
  border-radius: 100%;
  border: 2px solid transparent;
  padding: 3px;
  position: relative;
}

.referrals-card .body .statistics_item_child {
  width: 30px;
  height: 30px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.padding0 {
  padding: 0px;
}
