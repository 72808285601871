//Sidebar
$sidebar-bg-color: #fff;
$submenu-bg-color: #fff;
$submenu-bg-color-collapsed: #fff;
$sidebar-color: gray;
$highlight-color: black;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 20px;

$sidebar-width: 230px;

.pro-sidebar .pro-menu .pro-menu-item {
  margin-block: 1px;
  margin-inline: 10px;
  border: 1px solid transparent;
  background-color: #fbfbfb;
  border-radius: 10px;
}

.pro-sidebar .pro-menu {
  margin-top: 30px;
}
.pro-item-content {
  font-size: 1rem;
}

.pro-sidebar .pro-menu .pro-menu-item.active {
  color: #fff !important;
}
.app .pro-menu-item.active {
  background-color: #0080f9 !important;

  color: #fff !important;
}
@import "~react-pro-sidebar/dist/scss/styles.scss";

.app {
  // height: 100%;
  display: flex;
  position: relative;
  background-color: inherit;
  width: 100%;

  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // overflow-y: auto;
    // height: 100%;
    width: 100%;
    // background-color: #ffffff;
  }

  .block {
    display: flex;
    margin-top: 30px;
    // margin-bottom: 24px;
    // font-size: 14px;
    width: 100%;
    height: 100%;
    // color: #5454add_input54;
  }

  .pro-menu-item {
    &.active {
      background-color: rgba(0, 145, 218, 0.15);
      color: #fff;
      border-radius: 10px;
    }
  }
}
