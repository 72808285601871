@font-face {
  font-family: "hacen algeria";
  src: url(./Hacen-Algeria.ttf);
}
* {
  /* font-family: buckra; */
  font-family: "hacen algeria", Times, serif;
  font-size: 16px;
  /* font-weight: bold; */
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    /* max-width:540px; */
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.page-wrapper {
  width: 100%;
  height: 100%;
  /* background-color: white !important; */
  /* position: relative ;  */
  /* overflow: auto; */
  /* overflow-x: hidden; */
}
.pro-sidebar {
  z-index: 0 !important;
}
.text_black {
  color: #384364;
}

.sideItem {
  background: rgba(0, 190, 172, 0.2);
  border-radius: 10px;
  color: #384364;
  margin: 10px;
  transition: transform 0.5s ease-in-out;
}
/* .pro-arrow {
  border-color: #003595 !important;
} */

.react-slidedown pro-inner-list-item {
  background-color: transparent;
}

/* #adadad */

.page_title {
  color: #0091da;
  word-spacing: 1px;
  font-size: 20px;
  font-weight: bold;
}

.dropzone_div {
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
}
.dropzone_card {
  border-color: #0091da;
  border-radius: 12px;
}

.icon_app {
  margin-top: 42px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .form-inline {
    display: none;
  }
  .sidebar-desktop {
    display: none !important;
  }
  .dropzone_card {
    width: 150px;
    height: 150px;
  }
  .navbarSupportedContent {
    display: none;
  }
  #bill {
    display: none;
  }
  #user_icon {
    display: none;
  }

  .dropzone_logo {
    width: 50px;
    height: 50px;
  }
  .revenue-card .list {
    display: block;
  }
  .referrals-body {
    display: block;
  }
  .revenue-card .bizcharts {
    height: 200px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .dropzone_card {
    width: 150px;
    height: 150px;
  }

  .dropzone_logo {
    width: 50px;
    height: 50px;
  }
  .revenue-card .list {
    display: block;
  }
  .referrals-body {
    display: block;
  }
  .revenue-card .bizcharts {
    height: 200px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .dropzone_card {
    width: 150px;
    height: 150px;
  }
  .dropzone_logo {
    width: 50px;
    height: 50px;
  }
  .photo_float_left {
    float: left;
  }
  .photo_float_right {
    float: right;
  }
  .col_left {
    margin-left: -10%;
  }

  .col_right {
    margin-right: -10%;
  }
  .revenue-card .list {
    display: block;
  }
  .referrals-body {
    display: block;
  }
  .revenue-card .bizcharts {
    height: 200px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .dropzone_card {
    width: 150px;
    height: 150px;
  }
  .dropzone_logo {
    width: 50px;
    height: 50px;
  }
  .photo_float_left {
    float: left;
  }
  .photo_float_right {
    float: right;
  }
  .col_left {
    margin-left: -10%;
  }

  .col_right {
    margin-right: -10%;
  }
  .revenue-card .list {
    display: flex;
  }
  .referrals-body {
    display: flex;
  }
  .revenue-card .bizcharts {
    height: 250px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .dropzone_card {
    width: 120px;
    height: 120px;
  }
  .dropzone_logo {
    width: 50px;
    height: 50px;
  }

  .col_create {
    margin-left: -12%;
    margin-top: 1%;
  }
  .revenue-card .list {
    display: flex;
  }
  .referrals-body {
    display: flex;
  }
  .revenue-card .bizcharts {
    height: 250px !important;
  }
}
.MuiTypography-body1 {
  font-weight: bold !important;
}
.MuiSwitch-track {
  background-color: #0091da !important;
}

.cardStyle {
  border-radius: 10px;
  background-color: white;
  border: none;
  /* height: 50% !important; */
  padding: 10px 20px;
  display: block;
}

.cardSearch {
  border-radius: 10px;
  background-color: white;
  border: none;
  /* height: 50% !important; */
  padding: 10px 20px;
  display: block;
}

.cardStyle2 {
  border-radius: 10px;
  background-color: white;
  border: none;
}

.cardCreate {
  border-radius: 10px;
  background-color: white;
  border: none;
  height: 100% !important;
  padding: 10px 20px;
}

.ant-table-cell-row-hover {
  z-index: 0 !important;
}

.td_table {
  padding: 1.3%;
}
#page_alink {
  background-color: rgba(0, 190, 172, 0.3);
  border-radius: 50px;
  width: 30px;
  height: 30px;
  text-align: center;
}

#li_activeing {
  width: 30px;
  height: 30px;
  background-color: rgba(0, 190, 172, 0.3);
  color: white;
  /* border-radius: 5px; */
}
.MuiList-root
  MuiList-padding
  MuiMenu-list
  .css-6hp17o-MuiList-root-MuiMenu-list {
  display: flex;
  flexdirection: column;
  padding: 1rem;
  color: red;
}

#li_active_right_active {
  width: 30px;
  height: 30px;
  background-color: rgba(0, 190, 172, 0.3);
  color: white;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
}
#li_active_left_active {
  width: 30px;
  height: 30px;
  background-color: rgba(0, 190, 172, 0.3);
  color: white;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

#a_paginate {
  width: 30px;
  height: 30px;
  background-color: rgba(0, 190, 172, 0.3);
  color: white;
}

#a_paginateActive {
  width: 30px;
  height: 30px;
  background: white;
  border: none;
  color: #11c2b2;
  border-radius: 50px;
}

#pag_link_start {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 30px;
  height: 30px;
  background: rgba(0, 190, 172, 0.3);
  color: white;
}

#pag_link_end {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  width: 30px;
  height: 30px;
  background: rgba(0, 190, 172, 0.3);
  color: white;
}

#pag_link_start_active {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 30px;
  height: 30px;
  background: white;
  color: #11c2b2;
  border-radius: 50px;
}

#pag_link_end_active {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  width: 30px;
  height: 30px;
  background: white;
  color: #11c2b2;
  border-radius: 50px;
}

.button_close {
  position: absolute;
  z-index: 1000;
  float: right;
  top: 0%;
  right: 0%;
  background: rgba(0, 190, 172, 0.3);
  border-radius: 10px;
  color: white;
  font-size: 10px;
  border: none;
  box-shadow: 1px 1px 5px gray;
}

.button_close:hover {
  position: absolute;
  z-index: 1000;
  float: right;
  top: 0%;
  right: 0%;
  background: #00beac;
  border-radius: 10px;
  color: white;
  font-size: 10px;
  border: none;
  box-shadow: 1px 1px 5px gray;
}
.MuiDialog-paperWidthSm {
  border-radius: 10px !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  background-color: #00beac;
  color: white !important;
  border: none;
}

.PrivateTabIndicator-colorPrimary-5 {
  display: none !important;
}
.PrivateTabIndicator-colorPrimary-4 {
  display: none !important;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
  top: 50px !important;
}
/* .MuiButtonBase-root {
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  position: relative !important;
  box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  vertical-align: middle !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  text-decoration: none !important;
  color: inherit !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  -webkit-justify-content: flex-start !important;
  justify-content: flex-start !important;
  min-height: 48px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  white-space: nowrap !important;
  padding-left: 16px !important;
  padding-right: 16px !important; */
/* } */
.Mui-selected {
  text-decoration: underline !important;
}
.padding0 {
  padding: 0%;
}

.makeStyles-tab-3 {
  min-height: 70px !important;
}

.MuiMobileStepper-dotActive {
  background-color: #00beac !important;
}

.MuiMobileStepper-dot {
  background-color: none;
  border: 1px solid #00beac !important;
  padding: 4px;
}

.dots_pagination {
  background-color: white;
  border-radius: 40px;
  text-align: center;
  margin: 2px;
  width: 10px;
  height: 10px;
  display: inline-block;
  border: 1px solid #00beac;
}

.dots_pagination_active {
  background-color: #00beac;
  border-radius: 40px;
  text-align: center;
  margin: 2px;
  width: 10px;
  height: 10px;
  display: inline-block;
  border: 1px solid #00beac;
}

#alert_title {
  text-align: center;
}

.alert_btn {
  background: transparent;
  border: none;
  font-size: 10px;
  color: black;
}

.alert_btn:hover {
  background-color: rgba(153, 229, 222, 0.2);
  color: black;

  transition: transform 0.5s ease-in-out;
}

.phone_page_container {
  margin-top: 6px;
  border-radius: 10px;
  text-align: center;
  width: 100% !important;
  /* height: calc(1.5em + 0.75rem + 2px) !important; */
  height: 40px !important;
}
.phone_input {
  border-radius: 0 !important;
  text-align: center !important;
  width: 100% !important;
  height: 100% !important;
  /* height: 40px !important; */
}
.phone_input::placeholder {
  color: rgba(206, 206, 206, 0.8) !important;
  opacity: 1 !important;
  font-size: 11px !important;
}
.phone_btn {
  background-color: red;
}

.react-tel-input .flag-dropdown {
  border-radius: 10px !important;
  direction: ltr !important;
}
.react-tel-input .form-control {
  padding-left: 0px !important;
}

.ui.menu .red.active.item,
.ui.red.menu .active.item {
  background-color: #00beac;
  color: white !important;
  border: none;
}

.status_item {
  padding: 8px;
}
.active_status {
  color: #17a2ff;
  background-color: #c7e9ff;
}
.inactive_status {
  color: #ff6868;
  background-color: #ffdcdc;
}
.inputStyle:disabled {
  background-color: white;
}

.css-6q0nyr-Svg {
  color: white;
}

.phone_dropdown {
  font-size: 10px;
  text-align: center;
  background-color: white;
}

.MuiDialog-paperWidthSm {
  max-width: 1000px !important;
  /* background-color: #efefef !important; */
}

.btn_add_wfield {
  background-color: #00beac;
  border: 1px solid white;
  border-radius: 10px;
}

.btn_add_wfield:hover {
  background-color: #0cdbc7;
  border: 1px solid white;
  border-radius: 10px;
}

.report_card {
  border-radius: 10px;
  border-color: white;
  width: 98%;
}
.archive_card {
  border-radius: 10px;
  border: none;
  height: 100%;
}

.report_img {
  object-fit: cover;
  width: 70px;
  height: 70px;
  max-width: 100%;
  border-radius: 50%;
}
.report_div {
  position: relative;
  width: 80px;
  height: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  margin-top: -47px;
}
.report_btn_view {
  width: 120px;
  height: 20x;
  /* max-width: 100%; */
  background-color: white;
  color: #384364;
  border: none;
  box-shadow: 1px 1px 5px lightgray;
  border-radius: 15px;
}
.report_div_bottom {
  position: relative;
  width: 40%;
  height: 10%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 100%;
  direction: ltr;
  margin-bottom: -20px;
}

.report_btn {
  background-color: #00beac;
  border-color: #00beac;
  font-size: 8px;
  border-radius: 15px;
  top: 9px;
  right: 11px;
  position: absolute;
  width: 30%;
}
.sr-only {
  display: none;
}
.report_rate {
  font-size: 8px;
  border-radius: 15px;
  position: absolute;
  width: 30%;
  margin-top: 9%;
}

.report_btn_bottom {
  background-color: #384364;
  border-color: #384364;
  font-size: 8px;
  border-radius: 15px;
  bottom: 20px;
  left: 11px;
  position: absolute;
  width: 30%;
}

.report_text_label {
  font-size: 10px;
}

.green_text {
  color: #00beac;
}
.blue_text {
  color: #384364;
}
.desc_report {
  font-size: 11px;
  line-height: 20px;
  word-spacing: 2px;
  letter-spacing: 1px;
}
/* .btn_add_wfield:hover {
  background-color: white;
  border: 1px solid #00beac;
  border-radius: 20px;
  color: #00beac;
} */

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #00beac !important;
}

.alice-carousel__dots-item:not(.__custom) {
  background-color: white !important;
  border: 1px solid #00beac !important;
}

.sing_report_btn_green {
  background-color: #00beac;
  border: none;
  border-radius: 20px;
  color: white;
}

.sing_report_btn_blue {
  background-color: #384364;
  border: none;
  border-radius: 20px;
  color: white;
}

.flatpickr-rContainer {
  font-size: 14px !important;
}

.flatpickr-calendar.animate.open {
  font-size: 14px !important;
}

.dateStyle {
  /* border-color: #00beac; */
  padding: 0.2rem 0.5rem;
  color: black;
  /*text-align: center;*/
  border: none;
  height: 100%;
  border-radius: 0;
  background-color: #eeeeee !important;
  cursor: pointer;
  min-height: 42px;
  /* color: white !important; */
  width: 100%;
  font-size: 14px !important;
  /*font-weight: bold;*/
}
.dateStyle.form-control::placeholder {
  color: #757575;
  font-size: 14px;
}

.dateStyle:active {
  border-radius: 0;
  /*text-align: center;*/
  /* background-color: #00beac !important; */
  cursor: pointer;
  height: 100% !important;
  /* color: white !important; */
  width: 100%;
  min-height: 42px;
}
.dateStyle:focus {
  border: none;
  box-shadow: none;
}

.react-select-div {
  width: 250px;
  height: fit-content;
  margin: 10px;
}

.btn_search_style {
  border-radius: 35px;
  text-align: center;
  background-color: #0091da !important;
  cursor: pointer;
  height: 40px !important;
  width: 180px;
  color: white;
  position: relative;
  font-weight: bold;
  /* color: #003595 !important; */
}
.btn_Add_order_style {
  border-radius: 35px;
  text-align: center;
  background-color: #0091da !important;
  cursor: pointer;
  height: 40px !important;
  width: 180px;
  color: white;
  position: relative;
  font-weight: bold;
  /* color: #003595 !important; */
}
.btn_search_style:focus {
  color: #003595;
}
.btn_Add_order_style:focus {
  color: #003595;
}

.btn_search_style::after {
  content: "";
  width: 30px;
  height: 30px;
  background-image: url("../assets/SVGs/search-icon.svg");
  background-repeat: no-repeat;
  background-size: 15px 15px;
  top: 10px;
  left: 15px;
  position: absolute;
}

.btn_search_style:hover::after {
  background-image: url("../assets//SVGs/search-icon-white.svg");
}

/* .btn_search_style:hover {
  text-align: center;
  background-color: #003595 !important;
  border-color: white;
  cursor: pointer;
  height: 40px !important;
  color: white !important;
  box-shadow: 1px 1px 15px #003595;
  transition: box-shadow 0.5s ease-in-out;
} */

#test-table-xls-button {
  border-radius: 10px;
  /* border-color: #00beac; */
  text-align: center;
  background-color: #0091da !important;
  border: none;
  cursor: pointer;
  height: 40px !important;
  font-size: 14px;
  width: 180px;
  color: white;
  /* color: #00beac !important; */
}

/* #test-table-xls-button::before {
  content: "";
  position: absolute;
  left: 20px;
  top: 0px;
  display: inline-block;
  width: 30px;
  height: 40px;
  padding: 0;
  background-image: url("../assets/SVGs/download-icon-white.svg");
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px 0 0 10px;
} */

/* #test-table-xls-button:hover::before {
  background-color: white;
  background-image: url("../assets/SVGs/download-icon-svg.svg");
} */

#test-table-xls-button:hover {
  background-color: white !important;
  cursor: pointer;
  height: 40px !important;
  color: #0091da !important;
  font-size: 14px;
  /* width: 100px; */
  border: none;
  box-shadow: 1px 1px 15px #0091da;
  transition: box-shadow 0.5s ease-in-out;
}

.dateStyle::placeholder {
  /* Internet Explorer 10-11 */
  color: white;
  font-size: 14px;
}

.react-tel-input .country-list {
  border-radius: 10px !important;
  border: 1px solid #00beac !important;
  max-width: max-content;
}
.css-h6u8gf-control {
  border-color: white !important;
  box-shadow: none !important;
}

.tracking_card {
  background: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border: none;
}
.tracking_card_header {
  background-color: #05beac;
  color: white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.track_name {
  color: #00beac;
  font-size: 10px;
  letter-spacing: 1px;
}

.track_value {
  color: black;
  font-size: 10px;
  letter-spacing: 1px;
}
.circleBase {
  border-radius: 50%;
}

.place_btn {
  height: 35px;
  border-radius: 25px;
  background-color: #0091da !important;
  color: white;
  border: none;
  font-size: 14px;
  font-weight: bold;
}

.place_btn:hover {
  /* height: 35px;
  border-radius: 25px; */
  background-color: #0091da;
  color: white;
  /* border: 1px solid #00beac;
  border: none;
  box-shadow: 1px 1px 15px #00beac;
  transition: box-shadow 0.5s ease-in-out; */
}
.table_list {
  text-align: start;
  font-size: 10px;
}

.table_head {
  /* color: #00beac; */
  background: #eef9f8;
  font-size: 12px;
  border-top: hidden;
}

.span_title {
  font-size: 10px;
}

.inputStyle {
  border-radius: 0;
  background-color: #eeeeee;
  border: none;
  padding: 0.7rem;
  outline: none;
}
.inputStyle:focus {
  border: transparent;
  background-color: #eeeeee;
  box-shadow: none;
}

input {
  font-size: 18px !important;
}

input::-webkit-input-placeholder {
  font-size: 14px !important;
}

.inputStyle::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(133, 133, 133);
  opacity: 1;
  font-size: 16px;
  /*text-align: center;*/
 }

.ant-checkbox .ant-checkbox-inner {
  border-color: #1890ff;
}

.col_create:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(206, 206, 206, 0.8);
  font-size: 14px;
}

.inputStyle::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(206, 206, 206, 0.8);
  font-size: 14px;
}
.save_btn {
  background-color: rgb(0, 145, 218) !important;
  color: white;
  border-radius: 5px;
  border: none;
  /* padding-left: 12%;
  padding-right: 12%;
  padding-top: 2.3%;
  padding-bottom: 2.3%; */
  font-size: 12px;
  height: 45px;
  width: 200px;
}

.save_btn:hover {
  background-color: rgba(0, 190, 172, 0.5);
}

.save_btn:active {
  background-color: rgba(0, 190, 172, 0.5);
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
  background-image: url(../assets/img/point2.svg) !important;
}

.MuiSwitch-colorPrimary.Mui-checked {
  color: #0052cc !important;
}

.MuiSwitch-switchBase {
  color: white !important;
}

.pass-view {
  background-color: transparent;
  border-color: transparent;
  position: relative;
  color: white;
  z-index: 9999;
  border: none;
  top: -40%;
}

.react-confirm-alert-body {
  background-color: white !important;
  color: #00beac !important;
  font-size: 10px;
  text-align: center !important;
}

.react-confirm-alert-body > h1 {
  font-size: 20px !important;
  text-align: center;
}
.react-confirm-alert-button-group > button {
  background-color: #0091da !important;
  color: white !important;
  border-radius: 5px;
  border: none;
  font-size: 10px;
  height: 40px;
  width: 100px;
  text-align: center !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
.react-confirm-alert-button-group {
  text-align: center !important;
  display: block !important;
}
.save_btn:hover {
  background-color: rgba(0, 190, 172, 0.5);
}

.save_btn:active {
  background-color: rgba(0, 190, 172, 0.5);
}

.file_create {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  opacity: 0;
  background-color: transparent;
  color: transparent;
  width: 100%;
}

.btn_link {
  cursor: pointer;
  border-radius: 5px;
  background: #00beac;
  border: none;
  font-size: 12px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

span.status.f-online {
  background: #7fba00;
}

span.status {
  background: #bebebe none repeat scroll 0 0;
  border-radius: 50%;
  bottom: 0;
  display: inline-block;
  height: 12px;
  padding: 2px;
  position: absolute;
  right: 0;
  width: 12px;
}

span.status.f-away {
  background: #ffd300;
}

.im_chat {
  vertical-align: middle;
  border-style: none;
  border-radius: 100%;
  height: auto;
  max-width: 100%;
  border: none;
}

span.status::after {
  background: white none repeat scroll 0 0;
  border-radius: 100%;
  content: "";
  height: 6px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
}

.chat_div {
  display: inline-block;
  position: relative;
  width: 100%;
}

.MuiPagination-ul {
  justify-content: center;
  flex-wrap: wrap;
}

.home_card_main {
  height: 100%;
  display: flex;
  border: none;
  border-radius: 10px;
}

.div_home {
  align-items: center;
  height: 100%;
  /* margin: 10%; */
  margin: 20px;
}

table::-webkit-scrollbar {
  width: 8px;
}

/* Track */

table::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

table::-webkit-scrollbar-thumb {
  background: #00beac;
}

/* Handle on hover */

table::-webkit-scrollbar-thumb:hover {
  background: white;
  border: 1px solid #00beac;
}

.react-confirm-alert-overlay {
  z-index: 200000 !important;
}

.error_div {
  width: 100%;
  height: 100%;
  background-color: white;
}

.card_hours {
  background: rgba(0, 190, 172, 0.1);
  border: none;
}

.leaflet-marker-icon {
  border-radius: 100%;
}

.css-129a6gc-control {
  border: none !important;
}

.css-129a6gc-control:hover {
  border: none !important;
}
.css-ih1vw-control:hover {
  border: none !important;
}

.tracking_sidebar {
  border-radius: 10px;
  width: 20%;
  border: 2px solid #00beac;
}
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}
.leaflet-control-attribution a {
  display: none;
}
.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.MuiListItem-button:hover {
  background: rgba(0, 190, 172, 0.2);
}

.icon_profile_card {
  border-radius: 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  border: none;
  margin-inline: 5px;
}

.title_profile {
  font-size: 12px;
  font-size: 12px;
  font-weight: 100;
}

.phone_container {
  direction: ltr;
  height: 70%;
}

.cursor {
  cursor: pointer;
}

.flex {
  display: flex;
}

.alignItem {
  align-items: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-table-xls-button {
  background-color: #0091da;
  border-radius: 35px;
  color: white;
  padding: 0.3rem 0.5rem;
  margin-top: 0.2rem;
  align-items: center !important;
}

.header_content_img {
  /* margin-inline-start: 10px; */
}

.table_head .td_table {
  /* z-index: 10000; */
}

.need_visit_container .bg-light {
  padding: 15px 50px;
  background: #00beac !important;
  color: #fff;
  font-weight: bold;
  border: 1px solid #00beac;
  font-size: 15px;
}

.no_need_visit_container .bg-light {
  padding: 15px 50px;
  background: #fff !important;
  color: #00beac;
  font-weight: bold;
  border: 1px solid #00beac;
  font-size: 15px;
}

.create_visit_btn {
  background: #00beac !important;
  color: #fff;
  font-weight: bold;
  border: 1px solid #00beac;
  font-size: 15px;
}

.imgFull {
  width: 100%;
  height: 100%;
  position: relative;
}

.fullWH {
  width: 100%;
  height: 100%;
}

.margin0 {
  margin: 0px;
}
