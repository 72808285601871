.py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}
.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.bg-opacity-25 {
    --tw-bg-opacity: 0.25;
}
.bg-main {
    --tw-bg-opacity: 1;
    background-color: rgba(0 145 218);
}

.mt-8 {
    margin-top: 2rem;
}
button, [role="button"] {
    cursor: pointer;
}

.gap-x-2 {
    column-gap: 0.5rem;
}
.justify-evenly {
    justify-content: space-evenly;
}
.list-none {
    list-style-type: none;
}
.flex {
    display: flex;
}
.mt-10 {
    margin-top: 2.5rem;
}
.mx-auto {
    margin-left: auto;
    margin-right: auto;
}
ol, ul, menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
}
.bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: #4B5563;
}
.bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: #9CA3AF;
}
.rounded-full {
    border-radius: 9999px;
}
.cursor-pointer {
    cursor: pointer;
}
.w-2 {
    width: 0.5rem;
}
.h-2 {
    height: 0.5rem;
}